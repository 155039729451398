/* src/assets/css/Home.css */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

html, body {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

/* Dark mode styles */
.dark-mode {
  background-color: #121212;
  color: white;
}

.dark-mode .header {
  background-color: #1A1A1A;
}

/* Light mode styles (default) can stay as they are */

/* Add specific styles for the dark mode toggle button */
.dark-mode-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 12px;
}

.dark-mode-toggle span {
  margin-left: 8px;
}


.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #000000;
  color: #fff;
  padding: 10px;
  spacing-bottom: 100px;
  border-color: #f5f5f5;
}

.gradient-text {
  background: linear-gradient(to right, #9D50BB, #6E48AA);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block; /* Required to apply the gradient on text */
}

.user-details {
  justify-content: flex-end;
  padding: 10px;
  position: relative;
}
.breadcrumb{
  background: white;
      margin-bottom: 0rem !important;

}
.documentation {
  display: flex;
  align-items: center;
  position: relative;
}

.documentation span {
  margin-right: auto;
}

.documentation a {
  color: #ffffff; /* This color should match your header's text color */
  text-decoration: none; /* No underline */
  display: inline-block;
  align: right;
  padding: 5px 10px;
  transition: background-color 0.3s, color 0.3s;
}

.documentation p {
  margin: 0; /* Remove default margins from <p> */
  vertical-align: center;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  color: black;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  z-index: 1;
  display: block;
}

.user-name {
  margin-right: 10px;
  background-color: #1B75BC;
  border-radius: 5px;
  transition: background-color 0.3s;
  padding: 5px;
}

.button {
  background-color: #0c78ca;
  color: white;
  border: none;
  margin-top: 4px;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 8px;
  /* transition: background-color 0.3s; */
}
.btn-danger{
  /* margin-left: rem; */
  background-color:#93898a ;
}
.button:hover {
  background-color: #2980b9;
}
.btn-danger:hover{
  background-color:#93898a ;
}
.button-grp-save-cancel{
  display: flex;
  width: 35%;
  justify-content: flex-end;
}
.nav-column {
  width: 18%;
  background-color: #F8F8FF;
  float: left;
  padding: 20px;
  overflow-y: auto; /* Add scrollbar if content overflows */
  height: 107vh;
  border-right: 0.6px solid #d2d4d8; /* Border on the right side */
  box-shadow: 2px 0 6px rgba(0,0,0,0.05);
  overflow-y: auto;
}


/* DARK MODE CUSTOMIZATION */

.dark-mode .nav-column {
  background-color: black;
  border-color: gray;
  box-shadow: 5px 0 6px rgba(255, 255, 255, 0.1);
}

.dark-mode .body-column {
  background-color: black;
  height: calc(110vh - 20px);
  overflow-y: auto;
}

.dark-mode .content-column {
  background-color: black;
  height: calc(110vh - 20px);
  overflow-y: auto;
}

.dark-mode .nav-column button,
.dark-mode .nav-column li {
  color: #fff;
}

.dark-mode .active-menu-item .menu-icon {
  color: #fff;
}

.dark-mode .get-started-section {
  background-color: #333; /* Adjust the background color for better contrast */
  border-color: #555; /* Lighter border for visibility */
}

.dark-mode .service-box {
  border: 1px solid #555; /* Making the border visible in dark mode */
  background-color: #424242; /* Slightly lighter than the overall dark mode background for contrast */
}

.dark-mode .body-section input, .dark-mode .body-section select {
  background-color: #333; /* Dark background for the input */
  color: #fff; /* Light text color for contrast */
  border: 1px solid #555; /* Visible border */
}

.dark-mode .body-section input::placeholder, .dark-mode .body-section select {
  color: #ccc; /* Lighter text color for placeholders for contrast */
}

.dark-mode .user-dropdown {
  background-color: #333; /* Darker background for the dropdown */
  color: #fff; /* Light color for the text */
  border: 1px solid #555; /* Slightly lighter border for visibility */
}

/* Show the dropdown on user-info hover */
.user-info:hover .user-dropdown {
  display: block;
}

.dark-mode .field-description {
  color: #FFFDD0;
}

.dark-mode .repository-box {
  border: 1px solid #555; /* Making the border visible in dark mode */
  background-color: #424242; /* Slightly lighter than the overall dark mode background for contrast */
}

/* DARK MODE CUSTOMIZATION */

.nav-column button {
  border: none;
  padding: 5px;
  width: 100%;
  height: 100%;
  text-align: left;
  cursor: pointer;
  margin-bottom: 5px;
  border-radius: 5px;
  transition: background-color 0.3s;
  font-size: 1.0rem;
  background: transparent;
}

.nav-column button:hover {
  color: #1B75BC;
  font-weight: bold;
}

.nav-column ul {
  list-style: none;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s;
}

.nav-column li {
  padding: 5px 0;
  cursor: pointer;
  font-size: 1.0rem;
}

.nav-column li:hover {
  color: #1B75BC;
  font-weight: bold;
}

.nav-column li:active {
  color: #1B75BC;
  font-weight: bold;
}


.active-menu-item {
  font-weight: bold;
  color: #1B75BC;
}

.active-menu-item .menu-icon {
  color: #1B75BC;
}

.body-column {
  float: left;
  padding: 5px 28px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* height: calc(110vh - 20px); */
  overflow-y: auto;
}

.body-list table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.body-list th, .tenant-list td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.body-list td {
  padding: 8px;
  text-align: left;
}

.add-button {
  font-weight: bold;
  /* Add more styling as needed */
}

.body-section {
  flex: 0; /* Takes up the available space */
  padding-top: 20px;
  padding-bottom: 50px;
}

.body-section h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.body-section div {
  margin-bottom: 15px;
}

.body-section label {
  display: block;
  margin-bottom: 5px;
}

.body-section input[type=text] {
  width: 35%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.body-section select {
  width: 35%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 8px;
}
.body-section input[type=checkbox] {
 margin-right: 10px;
}
.body-section-checkbox {
  width: 40%;
}
.add-tenant-modal{
  display: flex;
  padding-left: 20px;
  /* justify-content: center; */
  width: 100%;
}
.add-tenant-modal .body-column{
  width: 100%; 
}
.add-tenant-modal .body-section input {
  width: 90%; 
}

.add-tenant-modal .body-section select {
  width: 90%; 
}

.field-description {
  color: #1B75BC;
  width: 60%;
  font-size: 0.9rem; /* Example font size */
  margin-top: 1rem; /* Adjust as needed */
}

.spinner {
  width: 50px;
  height: 50px;
  animation: spin 10s linear infinite;
  outline: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg); /* Start the rotation from 0 degrees */
  }
  to {
    transform: rotate(360deg); /* Rotate 360 degrees */
  }
}


.content-column {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: 20px;
}

.content-section {
  padding: 20px;
  margin-bottom: 20px;
}

.content-section h2 {
  font-size: 24px;
  margin-bottom: 10px;
  padding: 20px;
}

.content-section p {
  padding: 20px;
  border: 1px solid #ddd;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

content-section ol {
  padding: 20px;
  list-style-type: none;
  counter-reset: my-counter;
}

content-section ol li {
  position: relative;
  margin-bottom: 10px;
  padding-left: 30px;
}

content-section ol li::before {
  content: counter(my-counter);
  counter-increment: my-counter;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  font-weight: bold;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 50%;
  margin-right: 10px;
}

.colate-logo {
  font-family: 'Baumans', sans-serif;
}

.colate-logo .co {
  font-size: 47px;
  letter-spacing: 1px;
  padding-left: 20px;
  background: linear-gradient(to right,
    #DA22FF,  /* pinkish-purple */
    #FFA824   /* yellow-orange */
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.colate-logo .create {
  color: white;
  font-size: 47px;
}

.colate-logo a {
  text-decoration: none; /* Remove the underline */
  color: inherit; /* Inherit the color from the parent */
}


.colate-logo a {
  text-decoration: none; /* Remove the underline */
  color: inherit; /* Inherit the color from the parent */
}

.user-dropdown button {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 5px;
}

.user-email {
  font-weight: bold;
  margin-bottom: 5px;
}

/* Add this section for the alert message */
.failure-alert {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  margin-bottom: 20px;
}

.success-alert {
  background-color: #d7f8d9;
  color: #1c7223;
  padding: 10px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  margin-bottom: 20px;
}

/* Update the disabled button styles */
.button:disabled {
  background-color: #7fb2f0;
  border: none;
  padding: 8px 12px;
  cursor: not-allowed;
  border-radius: 5px;
  opacity: 0.7;
}

.button:disabled:hover {
  background-color: #7fb2f0;
  cursor: not-allowed;
}

.button:disabled span {
  opacity: 0.7;
  pointer-events: none;
}

.cluster-section {
  display: flex;
  align-items: flex-start;
}

.cluster-label {
  margin-bottom: 10px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip-text {
  visibility: hidden;
  width: 550px;
  text-align: left;
  border-radius: 4px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -125px;
  opacity: 0;
  transition: opacity 0.2s;
}

/* Tooltip arrow */
.tooltip-text::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
}

/* Show the tooltip on hover */
.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Adjust the .dashboard-container to include bottom margin space */
.dashboard-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px; /* Add space before the 'Get Started' section */
}

/* Style the 'Get Started' section to stand out */
.get-started-container {
  width: 100%;
  padding: 10px;
}

.getting-started-grid {
    overflow-y: auto;
}

.get-started-section {
  background-color: #F8F8FF;
  padding: 40px; /* More padding for emphasis */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* More pronounced shadow for focus */
  margin-top: 20px; /* Space from the above container */
  text-align: center; /* Center the text for this section */
}

/* Since 'Get Started' is now a standalone section, we may want to adjust its width on larger screens */
@media only screen and (min-width: 992px) {
  .get-started-container {
    width: 80%; /* Slightly less than full width for larger screens */
    margin: 40px auto; /* Center the container with automatic margins */
  }
}

.integration-hub {
  float: left;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(110vh - 20px);
  overflow-y: auto;
}

.service-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #F8F8FF;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    text-align: center;
    margin-bottom: 10px;
    width: 30%;
}

.service-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.service-box:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.service-box img {
  margin-bottom: 10px;
}

.service-box p {
  margin: 10px 0;
}

.menu-icon {
  margin-right: 8px; /* Space between the icon and text */
  vertical-align: middle; /* Align with the text */
  color: #778899;
  font-size: 17px; /* Adjust the size as needed */
}

.repository-box {
  border: 1px solid #ccc;
  background-color: #F8F8FF;
  padding: 20px;
  margin: 10px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  width: 40%;
}

.repository-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}


/* Responsive styles for smaller screens */
@media only screen and (max-width: 768px) {
  .nav-column {
    width: 100%;
    padding: 10px;
    overflow-y: auto;
  }

  .content-column {
    width: 100%;
    margin-left: 0;
    overflow-y: auto;
  }

  .body-column {
      padding: 10px;
    }

  .body-section {
    width: 100%;
    padding: 10px;
  }

  .body-list table {
    width: 100%;
    padding: 10px;
  }

  .body-list th, .tenant-list td {
    padding: 8px;
    padding: 10px;
  }

  .body-section label {
    width: 100%;
    margin-top: 20px;
  }

  .body-section input,
  .body-section select {
    width: 100%;
  }

  .button {
    margin-top: 20px;
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    .dashboard-box {
      width: 100%; /* Full width on smaller screens */
      flex: 0 0 auto; /* Adjusts flex settings for small screens */
    }
  }
}
